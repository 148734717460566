import { types } from "mobx-state-tree"
import Options from "./Options"

export const Questions = types
  .model("Questions", {
    id: types.maybe(types.number),
    type: types.maybe(types.string),
    order: types.maybe(types.number),
    title: types.maybe(types.string),
    question: types.maybe(types.string),
    answerType: types.maybe(types.string),
    options: types.maybe(types.array(Options)),
    required: types.maybe(types.boolean),
    isAnswer: types.maybe(types.boolean),
    createdAt: types.maybe(types.string),
    udpatedAt: types.maybe(types.string),
  })
  .views(self => ({
    get show() {
      return {...self}
    },
  }))
  .actions(self => ({
    async setData(data) {
      Object.keys(data).forEach(d => self[d] = data[d])
    },
    async setIsAnswer(isAnswer) {
      self.isAnswer = isAnswer
    },
    async reset() {
      self.id = 0
      self.type = ''
      self.order = 0
      self.title = ''
      self.question = ''
      self.answerType = ''
      self.options = []
      self.required = false
      self.isAnswer = false
      self.createdAt = ''
      self.udpatedAt = ''
    },
  }))

export default Questions