import React, { useEffect } from "react";
import { observer } from 'mobx-react';
import { useMst } from "../../../models";
import { animateScroll } from 'react-scroll';

const Step = observer(({ options, selectIndustry }) => {
  const { user } = useMst();

  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 50,
      delay: 0,
      smooth: true,
      offset: 0,
    })
  }, [])
  
  return (
    <>
      <p className="step-no">STEP 02</p>
      <h2>저희에게 직업군을 알려주세요.</h2>
      <p>정확한 정보를 알려주셔야 정확한 진단이 가능해요.</p>
      <div className="notice-wrapper">
        <div className="notice-content">
          <h4 className="notice">① 대분류(산업) - 소분류(직군) 순서로 진행됩니다.</h4>
          <h4 className="notice">② 학생 또는 구직자일 경우 희망 산업/직군 선택해주세요.</h4>
          <h4 className="notice">② 무직일 경우 산업군에서 "기타"를 선택해주세요.</h4>
        </div>
      </div>
      <div className="services-select-option">
        <ul>
          {options.map((label, i) => (
            <li className={"bg-white" + (user.industryId === i ? ' active' : '')} key={'one_' + i}
              onMouseEnter={(e) => e.currentTarget.classList.add('over')}
              onMouseLeave={(e) => e.currentTarget.classList.remove('over')}
              onClick={() => {
                selectIndustry(i);
              }}
            >
              <label>{label}
                <input
                  type="radio" name="industry" value={i}
                />
              </label>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
});

export default Step;