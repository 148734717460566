
import React, { useState } from 'react';
import moment from 'moment';
import background from '../assets/img/code.jpg';
import { getItem, setItem } from '../lib/localstorage';

const Code = () => {
  const [profile, setProfile] = useState(false);
  const [visibled, setVisibled] = useState(false);
  const [user, setUser] = useState(false);
  const [owner, setOwner] = useState({});
  const [history, setHistory] = useState([]);


  const useCode = async (e) => {
    const response = await fetch('https://api.icru.co.kr/api/v1/code/use-code-download', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'client-id': 'kr.co.icru',
        'user-id': 0,
        'authorization': 'Bearer ' + getItem('access-token'),
      },
      body: JSON.stringify({
        code: e.currentTarget.value,
      }),
    })

    const result = await response.json()
    console.log(result)
    if (result.result && result.code === 200) {
      setItem('access-token', response.headers.get('access-token'))
      setItem('refresh-token', response.headers.get('access-token'))
      setProfile(true)
      setUser(result.data.user)
      setOwner(result.data.owner)
      setHistory(result.data.history)
    }
  }

  const pdfDownload = async (historyId) => {
    const response = await fetch('https://api.icru.co.kr/api/v1/download/' + user.id + '/' + historyId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'client-id': 'kr.co.icru',
        'user-id': user.id,
        'authorization': 'Bearer ' + getItem('access-token'),
      }
    })

    // console.log(response)

    const blob = await response.blob()
    const fileName = user.name + ' 기질진단 레포트.pdf'

    // 가상 링크 DOM 만들어서 다운로드 실행
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
  }

  const sendEmail = async (historyId) => {
    step.setLoading(true)
    const response = await fetch('https://api.icru.co.kr/api/v1/send-email', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'client-id': 'kr.co.icru',
        'user-id': user.id,
        'authorization': 'Bearer ' + getItem('access-token'),
      },
      body: JSON.stringify({
        userId: user.id,
        historyId: historyId,
      }),
    })

    const result = await response.json()

    if (result.result && result.code === 200) {
      // console.log('이메일 발송 완료!!!')
      toast.success('[' + user.email + '] 이메일 발송 하였습니다.')
    } else {
      // toast.error('사용자 등록 실패!! 처음부터 다시 시도!')
    }
    step.setLoading(false)
  }

  return (
    <div className="wrapper" style={{ backgroundImage: `url(${background})`, height: '100vh' }}>
      {!visibled &&
        <div className="code">
          <div style={{ color: 'white' }}>관리자 비밀번호를 입력하세요.</div>
          <br />
          <div className="code-title">
            <input
              type="text"
              name="code"
              className="form-control required"
              maxLength="20"
              placeholder="비밀번호"
              aria-required
              style={{ width: '200px', height: '45px', borderRadius: '5rem', fontSize: '1.2rem', letterSpacing: '0.5rem', fontWeight: 500, marginBottom: '20px' }}
              onInput={(e) => { 
                if (e.currentTarget.value === 'icrutest0605!#%&') {
                  setVisibled(true)
                }
              }}
            />
          </div>
        </div>}
      {visibled &&
        <div className="code">
          <div style={{ color: 'white' }}>다운 받으실 코드번호를 입력하세요.</div>
          <br />
          <div className="code-title">
            <input
              type="text"
              name="code"
              className="form-control required"
              maxLength="6"
              placeholder="코드번호입력"
              required
              style={{ width: '200px', height: '45px', borderRadius: '5rem', fontSize: '1.2rem', letterSpacing: '0.5rem', fontWeight: 500, marginBottom: '20px' }}
              onInput={(e) => {
                if (e.currentTarget.value.length === 6) {
                  useCode(e)
                }
              }}
            />
          </div>
        </div>}
      { profile &&
        <div style={{textAlign:'center'}}>
          <div style={{ color: 'white' }}>센터 : { owner.name }</div>
          <div style={{ color: 'white' }}>진단자 : {user.name} ({user.birthYear})</div>
          <div style={{ color: 'white' }}>E-mail : {user.email}</div>
          <br />
          <div style={{ color: 'white' }}>[기질진단 내역]</div>
          {history.map((h, i) => (
            <div style={{ color: 'white' }} key={i}>
              {i + 1}. 타입 : {h.type}, 진단일 : {moment(h.updatedAt).format('YYYY-MM-DD HH:mm')} 
              &nbsp;&nbsp;
              <a href='#' onClick={() => {
                pdfDownload(h.id)
              }}>[다운로드]</a>&nbsp;&nbsp;
              <a href='#' onClick={() => {
                sendEmail(h.id)
              }}>[이메일전송]</a>
            </div>
          ))

          }
        </div>
      }
    </div>
  )
};

export default Code;