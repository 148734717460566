
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getItem } from '../lib/localstorage';
import { useMst } from "../models";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const base = {
  R: {
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    17: 17,
    18: 18,
    19: 19,
    20: 20,
    21: 21,
    22: 22,
    23: 23,
    24: 25,
    25: 27,
    26: 29,
    27: 30,
    28: 31,
    29: 33,
    30: 34,
    31: 35,
    32: 38,
    33: 39,
    34: 41,
    35: 42,
    36: 44,
  },
  E: {
    9: 9,
    10: 11,
    11: 12,
    12: 13,
    13: 14,
    14: 15,
    15: 16,
    16: 17,
    17: 18,
    18: 19,
    19: 20,
    20: 21,
    21: 22,
    22: 23,
    23: 24,
    24: 25,
    25: 26,
    26: 27,
    27: 28,
    28: 29,
    29: 30,
    30: 31,
    31: 32,
    32: 33,
    33: 34,
    34: 38,
    35: 40,
    36: 44,
  },
  A: {
    9: 9,
    10: 12,
    11: 15,
    12: 18,
    13: 19,
    14: 20,
    15: 21,
    16: 22,
    17: 23,
    18: 25,
    19: 26,
    20: 27,
    21: 29,
    22: 30,
    23: 31,
    24: 32,
    25: 33,
    26: 34,
    27: 35,
    28: 36,
    29: 37,
    30: 38,
    31: 39,
    32: 40,
    33: 41,
    34: 42,
    35: 43,
    36: 44,
  },
  D: {
    9: 9,
    10: 11,
    11: 13,
    12: 14,
    13: 15,
    14: 16,
    15: 17,
    16: 18,
    17: 19,
    18: 20,
    19: 21,
    20: 22,
    21: 23,
    22: 24,
    23: 25,
    24: 26,
    25: 27,
    26: 28,
    27: 29,
    28: 30,
    29: 31,
    30: 32,
    31: 33,
    32: 34,
    33: 38,
    34: 39,
    35: 42,
    36: 44,
  }
}

const strengthBase = {
  R: {
    US: 33,
    VS: 30,
    S: 27,
    M: 23,
    L: 16,
    VL: 9,
  },
  E: {
    US: 35,
    VS: 33,
    S: 29,
    M: 22,
    L: 15,
    VL: 9,
  },
  A: {
    US: 31,
    VS: 26,
    S: 22,
    M: 17,
    L: 12,
    VL: 9,
  },
  D: {
    US: 34,
    VS: 32,
    S: 28,
    M: 21,
    L: 14,
    VL: 9,
  },
}

const Code = () => {
  const { user, step } = useMst();
  const [isLoading, setIsLoading] = useState(true)
  const [historyId, setHistoryId] = useState(null)
  const [isAnswer, setIsAnswer] = useState(false)
  const [a, setA] = useState('')
  const [b, setB] = useState(0.0)
  const [c, setC] = useState({})
  const [d, setD] = useState({})
  const [e, setE] = useState({})

  const [type, setType] = useState(1)

  const navigate = useNavigate();
  const reportType = step.reportType ? ('/' + step.reportType) : ''

  const groupSendMail = [
    '창원센터 24.12.06',
    '대전센터 24.11.29',
    '영등포 센터 24.12.06',
    '영등포센터 24.11.29',
    'test'
  ]


  useEffect(() => {
    if (!step.testStep || step.testStep < 1) {
      navigate('/');
      return
    }
    if (step.testStep > step.openStep || !step.openStep) {
      step.setOpenStep(step.testStep);
      return
    }
    setType(step.type)

    const questionTemp = []
    step.showQuestions.forEach(q => {
      questionTemp.push(q.toJSON())
    })

    const temp = []
    const sum = {
      A: 0,
      D: 0,
      R: 0,
      E: 0,
    }

    const normalized = {
      A: 0,
      D: 0,
      R: 0,
      E: 0,
    }

    const strength = {
      A: '',
      D: '',
      R: '',
      E: '',
    }

    step.showAnswer.forEach((q, i) => {
      if (!q.toJSON().questionsId || q.toJSON().questionsId === 0) {
        temp.push({
          type: questionTemp[i].type,
          answerType: questionTemp[i].answerType,
          title: questionTemp[i].title,
          userId: user.id,
          questionsId: questionTemp[i].id,
          answer: questionTemp[i].options,
          options: questionTemp[i].options,
          read: questionTemp[i].read,
          createdAt: '',
          udpatedAt: '',
        })
      } else {
        temp.push(q.toJSON())
      }

      if (temp[i].answerType === 'rank') {
        sum[temp[i].answer[0].read] += 4
        sum[temp[i].answer[1].read] += 3
        sum[temp[i].answer[2].read] += 2
        sum[temp[i].answer[3].read] += 1
      }
    })

    normalized.A = base.A[sum.A]
    normalized.D = base.D[sum.D]
    normalized.R = base.R[sum.R]
    normalized.E = base.E[sum.E]

    let type = ''
    const deviationArray = []
    Object.keys(normalized).forEach((n => deviationArray.push(normalized[n])))

    Object.keys(sum).forEach((s => {
      Object.keys(strengthBase[s]).forEach((t => {
        if (!strength[s] && sum[s] >= strengthBase[s][t]) {
          strength[s] = t
          if (t === 'US') {
            let original = true
            Object.keys(sum).forEach((st => {
              if (st !== s && sum[st] >= strengthBase[st]['S']) original = false
            }))

            if (original) type = s
          }
        }
      }))
    }))

    let max = 0
    let maxType = ''
    if (!type) {
      Object.keys(normalized).forEach((s => {
        if (max < normalized[s]) {
          max = normalized[s]
          maxType = s
        }
      }))

      max = 0
      Object.keys(normalized).forEach((s => {
        if (s !== maxType && max < normalized[s]) {
          max = normalized[s]
          type = s
        }
      }))

      type = maxType + type
    }

    const n = deviationArray.length
    const mean = deviationArray.reduce((a, b) => a + b) / n
    const deviation = Math.floor(Math.sqrt(deviationArray.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n) * 10) / 10

    setA(type)
    setB(deviation)
    setC(sum)
    setD(normalized)
    setE(strength)

    console.log(' == answerFetchData ==')

    if (!isAnswer) {
      answerFetchData(temp, type, sum, normalized, strength, deviation)
      setIsAnswer(true)
    }

    // setTimeout(() => setIsLoading(false), 3000)
  }, [])

  const reporting = async (hId) => {
    setHistoryId(hId);
    const response = await fetch('https://api.icru.co.kr/api/v1/report' + reportType, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'client-id': 'kr.co.icru',
        'user-id': user.id,
        'authorization': 'Bearer ' + getItem('access-token'),
      },
      body: JSON.stringify({
        userId: user.id,
        historyId: hId,
        code: step.code,
      }),
    })

    const result = await response.json()

    console.log(groupSendMail.includes(step.groupName))
    console.log(step.groupName)
    if ((result.result && result.code === 200) || reportType === '/group') {
      console.log('진단 완료!!! ' + step.type)
      if (step.type === 'debriefer' || groupSendMail.includes(step.groupName)) sendEmail(hId)
    } else {
      // toast.error('사용자 등록 실패!! 처음부터 다시 시도!')
    }
  }

  const historyFetchData = async (type1, sum, normalized, strength, deviation) => {
    const response = await fetch('https://api.icru.co.kr/api/v1/history', {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'client-id': 'kr.co.icru',
        'user-id': user.id,
        'authorization': 'Bearer ' + getItem('access-token'),
      },
      body: JSON.stringify({
        userId: user.id,
        type: type1,
        sum: sum,
        normalized: normalized,
        strength: strength,
        deviation: deviation,
        title: 'icru',
        reportType: step.reportType,
        code: step.code,
      }),
    })

    const result = await response.json()

    if (result.result && result.code === 200) {
      await reporting(result.data.id);
      if (type === 'debriefer') {
        step.reset();
      }
    } else {
      // toast.error('사용자 등록 실패!! 처음부터 다시 시도!')
    }
  }

  const answerFetchData = async (temp, type, sum, normalized, strength, deviation) => {

    const response = await fetch('https://api.icru.co.kr/api/v1/answer', {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'client-id': 'kr.co.icru',
        'user-id': user.id,
        'authorization': 'Bearer ' + getItem('access-token'),
      },
      body: JSON.stringify(temp),
    })

    const result = await response.json()

    if (result.result && result.code === 200) {
      historyFetchData(type, sum, normalized, strength, deviation)
    } else if (result.code === 601) {
      toast.error('이미 진단한 내역이 있습니다. 확인 바랍니다.')
      historyFetchData(type, sum, normalized, strength, deviation)
    } else {
      // toast.error('사용자 등록 실패!! 처음부터 다시 시도!')
    }
  }

  const pdfDownload = async () => {
    if (!step.testStep || step.testStep < 1) {
      navigate('/');
      return
    }
    if (step.testStep > step.openStep || !step.openStep) {
      step.setOpenStep(step.testStep);
      return
    }
    step.setLoading(true)
    console.log(reportType)
    const response = await fetch('https://api.icru.co.kr/api/v1/download/' + user.id + '/' + historyId + reportType, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'client-id': 'kr.co.icru',
        'user-id': user.id,
        'authorization': 'Bearer ' + getItem('access-token'),
      }
    })

    // console.log(response)

    const blob = await response.blob()
    const fileName = user.name + ' 기질진단 레포트.pdf'

    // 가상 링크 DOM 만들어서 다운로드 실행
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)

    step.setLoading(false)
  }

  const sendEmail = async (hId) => {
    if (!step.testStep || step.testStep < 1) {
      navigate('/');
      return
    }
    if (step.testStep > step.openStep || !step.openStep) {
      step.setOpenStep(step.testStep);
      return
    }

    step.setLoading(true)
    console.log({
      userId: user.id,
      historyId: hId ? hId : historyId,
      type: step.type,
      premium: step.premium
    })

    const response = await fetch('https://api.icru.co.kr/api/v1/send-email', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'client-id': 'kr.co.icru',
        'user-id': user.id,
        'authorization': 'Bearer ' + getItem('access-token'),
      },
      body: JSON.stringify({
        userId: user.id,
        historyId: hId ? hId : historyId,
        type: step.type,
        premium: step.reportType
      }),
    })

    const result = await response.json()

    if (result.result && result.code === 200) {
      // console.log('이메일 발송 완료!!!')
      if (type !== 'debriefer' || groupSendMail.includes(step.groupName))
        toast.success('[' + user.email + '] 이메일 발송 하였습니다.')
      else 
        toast.success('ICRU 디브리퍼에게 이메일 발송 하였습니다.')
    } else {
      // toast.error('사용자 등록 실패!! 처음부터 다시 시도!')
    }
    step.setLoading(false)
  }

  return (
    <>
      {/* <section id="thank-you" className="thank-you-section">
      <div className="container">
        <div className="thank-you-wrapper position-relative thank-wrapper-style-one">
          <div className="thank-txt text-center disabled">
            <div className="thank-icon">
              <img src={require("../assets/img/tht1.png")} alt="" width="130px" />
            </div>
            <h1>진단분석이 완료되었습니다.</h1>
            <p>진단분석 결과 수령방법을 선택하세요!</p>
            <p>type : {a}, 표준편차 : {b}</p>
            <p>SUM - R : {c.R}, E : {c.E}, A : {c.A}, D : {c.D}</p>
            <p>normalized - R : {d.R}, E : {d.E}, A : {d.A}, D : {d.D}</p>
            <p>strength - R : {e.R}, E : {e.E}, A : {e.A}, D : {e.D}</p>
            <div className="actions">
              <ul>
                <li onClick={() => { }}><a className="d-block text-center text-uppercase" href="/">이메일 전송</a></li>
                <li style={{width: '50px'}}></li>
                <li onClick={() => { }}><a className="d-block text-center text-uppercase" href="/">PDF 다운로드</a></li>
              </ul>
            </div>
              
          </div>
          <div className="th-bottom-vector position-absolute">
            <img src={require("../assets/img/tv1.png")} alt="" />
          </div>
        </div>
      </div>
    </section> */}

      <div className='home'>
        <div className="wrapper">
          <div className='logo'>
            <h1>ICRU</h1>
          </div>
          <div className="start-button">
            <div className='button'>
              <div style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>
                <div className="step-progress">
                  <div className="progress" style={{ border: 'none' }}>
                    <div className="progress-bar" style={{ width: '100%' }}></div>
                  </div>
                </div>
              </div>
              <div style={{
                fontFamily: 'Pretendard-Variable-Black',
                width: '550px',
                marginLeft: '-125px',
                padding: '20px',
                backgroundColor: 'white',
                marginTop: '20px',
                borderRadius: '20px'
              }}>
                {type === 'debriefer' &&
                  <>
                    <h4 style={{ padding: '10px' }}>{step.lang === 'ko' ? '진단이 완료되었습니다.' : 'Diagnosis is complete.' }</h4>
                    <p style={{ fontSize: '1.1rem' }}>{step.lang === 'ko' ? '감사합니다. ICRU디브리퍼에게 결과가 전달되었습니다.' : 'Thank you The results were delivered to the ICRU debriefer.'} <br />
                      {step.lang === 'ko' ? 'ICRU디브리퍼가 이 결과를 토대로 여러분의 삶을 함께 여정 해 주실겁니다.' : 'ICRU Debriefer will journey with you through your life based on these results.'}<br />
                      {step.lang === 'ko' ? '곧 뵙겠습니다!' : 'See you soon!' }</p>
                  </>
                }
                {type !== 'debriefer' &&
                  <>
                    <h4 style={{ padding: '10px' }}><b>고생하셨습니다!</b></h4>
                    <h4 style={{ paddingBottom: '10px' }}><b>진단분석이 완료되었습니다.</b></h4>
                    <p style={{ padding: '10px' }}>결과레포트 수령 방법을 선택해주세요 : )</p>
                    <div className='container' style={{ paddingTop: '30px' }}>
                      <div className="actions" style={{ color: '#6600ff' }}>
                        <ul>
                          <li onClick={() => { }}><a className="d-block text-center text-uppercase" href="#" onClick={() => sendEmail()}
                            style={{ backgroundColor: '#ebddff', fontWeight: 900, width: '150px', height: '50px', paddingTop: '15px', borderRadius: '35px' }}>이메일 발송</a></li>
                          <li style={{ width: '50px' }}></li>
                          <li onClick={() => { }}
                            style={{ backgroundColor: '#ebddff', fontWeight: 900, width: '150px', height: '50px', paddingTop: '15px', borderRadius: '35px' }}>
                            <a className="d-block text-center text-uppercase" href="#" onClick={pdfDownload}>PDF 다운받기</a></li>
                        </ul>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
          <div className='bottom-logo'>
            ⓒ<img src={require("../assets/img/logo2.png")} alt="" />
          </div>
        </div>
      </div>
    </>
  )
};

export default Code;