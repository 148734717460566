import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import Report from './pages/report';
import Test from './pages/test';
import Home from './pages/home';
import Code from './pages/code';
import Download from './pages/download';
import Debriefer from './pages/debriefer';
import Loading from './pages/loading';
import Agree from './pages/agree';
import SendMail from './pages/sendmail';
import PageNotFound from './pages/404'
import { useMst } from "./models"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { observer } from 'mobx-react';

const getBasename = path => path.substr(0, path.lastIndexOf('/'));

const App = observer(() => {
  const { step } = useMst();

  return (
    <>
      <Router basename={getBasename(window.location.pathname)}>
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/code' element={<Code />} />
          <Route path='/loading' element={<Loading />} />
          <Route path='/test' element={<Test />} />
          <Route path='/report' element={<Report />} />
          <Route path='/agree' element={<Agree />} />
          <Route path='/download' element={<Download />} />
          <Route path='/debriefer' element={<Debriefer />} />
          <Route path='/sendmail' element={<SendMail />} />
          <Route component={PageNotFound} />
        </Routes>
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark'
      />
      <div style={{ display: step.loading ? '' : 'none', backgroundColor: '#000', opacity: 0.7, height: '100vh', marginTop: '-100vh', left: 0, textAlign: 'center' }}>
        <img src={require("./assets/img/loading.gif")} alt="" width="200px" style={{ marginTop: '45vh' }} />
      </div>
    </>
  );
});

export default App;